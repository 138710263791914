import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Modal, Backdrop, IconButton } from "@material-ui/core"
import {
    Card,
    Slide,
    CardContent,
    CardHeader,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    ButtonGroup,
    Stack
} from "@mui/material"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import Moment from "moment-timezone"
import Wordcloud from "./Wordcloud"
import CloseIcon from "@mui/icons-material/Close"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone.js"
import utc from "dayjs/plugin/utc"
import * as customParseFormat from "dayjs/plugin/customParseFormat"
import { getGraphData } from "../utils/functions"
import { SINGLE_GRAPH_DURATION } from "../utils/constants"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DashboardAlert from "./page/DashboardAlert"
import dateJson from "./static/data/date.json"
import Switcher from "./common/Switcher"
import { marketStatusItems, alertTypes } from "../constants"
import { generateAlerts } from "../utils/functions"
import alertsJSON from "../components/static/data/alerts.json"
import GraphLoading from "./static/graph_loading.gif"
import LoadingOverlay from "react-loading-overlay"
import Joyride, { ACTIONS, EVENTS } from "react-joyride"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
const GRAPH_NORMALIZE_FACTOR = 20

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

let initialStockOptions = {
    buzz: {
        chart: {
            type: "bar"
        },
        plotOptions: {
            series: {
                stacking: "normal"
            },
            visible: false
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "Buzz Volume",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        series: [
            {
                data: [{ x: 1, y: 13 }]
            }
        ]
    },
    sentiment: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ]
            }
        ]
    },
    change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ],
                color: "#43a84a",
                negativeColor: "#e64736",

                type: "bar",
                pointStart: 0
            }
        ]
    }
}

const sortSeries = (data1, data2, key) => {
    let multiplier =
        (key === "sentiment") | (key === "sentimentChange") ? 0.5 : 1
    const MULTIPLIER = key === "buzz" ? GRAPH_NORMALIZE_FACTOR : 1

    const dataList = Object.keys(data1)
        .map((key) => {
            return {
                value:
                    multiplier * data1[key] +
                    multiplier * data2[key] * MULTIPLIER,
                name: key,
                d1: data1[key],
                d2: data2[key]
            }
        })
        .filter((item) => item.name !== "FB")

    dataList.sort((a, b) => (a.value < b.value ? 1 : -1)).splice(30, 1)

    // if (key === "buzz") {
    //     //sortSeries

    //     let tickerList = dataList.map((t) => t.name)
    //     tickerList = tickerList.filter((item) => {
    //         return item !== "fields"
    //     })
    //     return tickerList
    // }
    if (key === "buzzChange") {
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        tickerList = tickerList.filter((item) => {
            return item !== "fields"
        })
        topTickerList = topTickerList.filter((item) => {
            return item !== "fields"
        })

        return { all: tickerList, top: topTickerList }
    } else if (
        (key === "buzz") |
        (key === "sentiment") |
        (key === "sentimentChange")
    ) {
        //sortSentimentSeries
        let tickerList = dataList
            .sort((a, b) => a.value < b.value)
            .map((t) => t.name)

        let topTickerList = tickerList.slice(0, 10)

        tickerList = tickerList.filter((item) => {
            return item !== "fields"
        })
        topTickerList = topTickerList.filter((item) => {
            return item !== "fields"
        })

        return { all: tickerList, top: topTickerList }
    }
}

const getSeries = (tickerList, data1, data2, data3, data4) => {
    let data = {}

    if (data3 && data4) {
        data = tickerList.map((key) => {
            const newsPercent = data1[key]
            const stPercent = data2[key]
            const newsAbs = data3[key]
            const stAbs = data4[key]
            let y = 0
            if (newsPercent && stPercent) {
                y = parseFloat(0.5 * newsPercent + 0.5 * stPercent)
            } else if (newsPercent) {
                y = newsPercent
            } else if (stPercent) {
                y = stPercent
            }

            return {
                y,
                newsPercent,
                stPercent,
                newsAbs,
                stAbs
            }
        })
    } else {
        data = tickerList.map((key) => {
            const news = data1[key]
            const st = data2[key]
            let y = 0
            if (st && news) {
                y = parseFloat(0.5 * st + 0.5 * news)
            } else if (st) {
                y = st
            } else if (news) {
                y = news
            }
            return {
                y,
                st,
                news
            }
        })
    }

    return {
        name: "Score",
        type: "bar",
        data,
        pointStart: 0,
        color: "#43a84a",
        negativeColor: "#e64736"
    }
}

function getSeriesTwo(data1, data2, tickerList) {
    const data = tickerList
        .map((key) => {
            const st = data2[key] * GRAPH_NORMALIZE_FACTOR
            const news = data1[key]

            return {
                y: parseFloat(news + st),
                st,
                news
            }
        })
        .sort((a, b) => a.y < b.y)

    return {
        name: "Score",
        type: "bar",
        data: data,
        pointStart: 0,
        color: "#43a84a",
        negativeColor: "#e64736"
    }
}

const findSeries = (Sname, data, changeFlag) => {
    let data1 = null
    let data2 = null
    let data3 = null
    let data4 = null
    if (changeFlag) {
        data1 = data.find((o) => o.fields === Sname + "_st_change_percent")
        data2 = data.find((o) => o.fields === Sname + "_news_change_percent")
        data3 = data.find((o) => o.fields === Sname + "_st_change")
        data4 = data.find((o) => o.fields === Sname + "_news_change")
        return { news: data2, st: data1, newsAbs: data4, stAbs: data3 }
    } else {
        data1 = data.find((o) => o.fields === Sname + "_st")
        data2 = data.find((o) => o.fields === Sname + "_news")

        return { news: data2, st: data1 }
    }
}

const useGraphUpdate = (graphData, graphViews, key, processFunction) => {
    useEffect(() => {
        if (graphData[key]) {
            processFunction(graphData[key])
        }
    }, [graphData[key], graphViews[key], processFunction])
}

const STEPS = [
    {
        target: ".step-one",
        content: (
            <span>
                Welcome to the SSi Sentiment Dashboard! Daily updates, generated
                an hour before the market opens. Make informed investment
                decisions using sentiment trend analysis and trending keywords.
                Analyze sentiment changes pre-market for winning trades using
                alternate date, and assess social and credit risk for companies.
            </span>
        ),
        disableBeacon: true,
        placement: "left",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },

    {
        target: ".step-two",
        content: (
            <div>
                <span>
                    Explore the Change in Sentiment Dashboard, illustrating
                    average sentiment shifts over various periods compared to
                    today's opening. Hover over the graph for tooltips detailing
                    sentiment changes from news and social media sources.
                </span>
            </div>
        ),
        placement: "left",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    {
        target: ".step-three",
        content: (
            <div>
                <span>
                    Click on predefined durations like 7 days to see sentiment
                    changes ((Today's Sentiment - Average Sentiment Over 7 Days)
                    / Average Sentiment Over 7 Days) * 100. Gain insights into
                    sentiment trends over specific timeframes.
                </span>
            </div>
        ),
        placement: "bottom",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    {
        target: ".step-four",
        content: (
            <div>
                <span>
                    View graphs in full screen for a detailed analysis. Press
                    escape to easily return to the normal view.
                </span>
            </div>
        ),
        placement: "bottom",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    {
        target: ".step-five",
        content: (
            <div>
                <span>
                    Click on any bar graph to switch to a single stock view,
                    displaying percentage sentiment changes for the selected
                    stock over various durations. Return to the default view by
                    clicking on any bar graph again.
                </span>
            </div>
        ),
        placement: "right",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    {
        target: ".step-six",
        content: (
            <div>
                <span>
                    Discover the Word Cloud, showcasing top trending keywords,
                    co-mentioned companies, persons, and authors for the
                    selected Ticker, Duration and Source (News or Social Media).
                    You can hover over the word cloud to see additional details.
                    Uncover valuable insights into market sentiment.
                </span>
            </div>
        ),
        placement: "top",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    {
        target: ".step-seven",
        content: (
            <div>
                <span>
                    Click on entities to toggle word clouds on/off for a focused
                    analysis. Customize your view for a more tailored and
                    insightful experience.
                </span>
            </div>
        ),
        placement: "left",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    }
]

const TrendingGraphs = (props) => {
    const classes = useStyles()
    const [graphModalToggle, setGraphToggle] = useState(true)
    const [graphViews, setGraphViews] = useState({
        buzz: "one_day",
        sentiment: "one_day",
        changeBuzz: "one_day",
        changeSentiment: "one_day"
    })
    const [graphDataCount, setGraphDataCount] = useState({
        buzz: false,
        sentiment: false,
        changeBuzz: false,
        changeSentiment: false
    })
    const [buzzGraph, setBuzzGraph] = useState(initialStockOptions["buzz"])
    const [buzzGraphChange, setBuzzGraphChange] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraph, setSentiGraph] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraphChange, setSentiGraphChange] = useState(
        initialStockOptions["change"]
    )
    const [datesList, setDatesList] = useState([])
    const [displayDates, setDisplayDates] = useState({
        buzz: "",
        sentiment: "",
        changeBuzz: "",
        changeSentiment: ""
    })
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [graphDateTime, setGraphDateTime] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const screen1 = useFullScreenHandle()
    const screen2 = useFullScreenHandle()
    const screen3 = useFullScreenHandle()
    const screen4 = useFullScreenHandle()
    const [btnGroups, setbtnGroups] = useState([1, 1, 1, 1])
    const [marketStatusKey, setMarketStatusKey] = useState(marketStatusItems[0])
    const [graphData, setGraphData] = useState({
        buzz: null,
        sentiment: null,
        changeBuzz: null,
        changeSentiment: null
    })
    const [alerts, setAlerts] = useState(alertsJSON["PRICE"])
    const [displayDate, setDisplayDate] = useState(null)
    const [hideAlertsToggle, setHideAlertsToggle] = useState(false)
    const [alertType, setAlertType] = useState(alertTypes[0])
    const [run, setRun] = useState(false)
    const [steps, setSteps] = useState(STEPS)
    const [stepIndex, setIndex] = useState(0)

    useEffect(() => {
        if (props.modalToggle) {
            setGraphToggle(() => {
                return true
            })
        }
    }, [props.modalToggle])

    useEffect(() => {
        props.setModalToggle(graphModalToggle)
    }, [graphModalToggle])

    useEffect(() => {
        setViews(1, "one_day")
        setViews(2, "one_day")
        setViews(3, "one_day")
        setViews(4, "one_day")
    }, [datesList])

    const mapStockDataByDuration = (durationData, stock) => {
        if (stock !== "FB/META") return durationData[stock]
        else return durationData["FB"] ?? durationData["META"]
    }

    const getSingleAbsoluteGraphData = (data, stock) => {
        const labels = [
            "one_day_st",
            "one_week_st",
            "two_week_st",
            "one_month_st",
            "one_day_news",
            "one_week_news",
            "two_week_news",
            "one_month_news"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st" ||
                        durationData.fields === "one_week_st" ||
                        durationData.fields === "two_week_st" ||
                        durationData.fields === "one_month_st"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news" ||
                        durationData.fields === "one_week_news" ||
                        durationData.fields === "two_week_news" ||
                        durationData.fields === "one_month_news"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    const getSingleBuzzGraphData = (arr) => {
        // Check if the array has an odd length
        if (arr.length % 2 !== 0) {
            return "Array length must be even."
        }

        // Find the middle index
        const middleIndex = arr.length / 2

        // Divide the array into two halves
        const firstHalf = arr.slice(0, middleIndex)
        const secondHalf = arr.slice(middleIndex)

        const result = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: firstHalf.map((prevIndex, currIndex) => {
                const st = firstHalf[currIndex] * GRAPH_NORMALIZE_FACTOR
                const news = secondHalf[currIndex]
                const y = st + news

                return {
                    y,
                    st,
                    news
                }
            }),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        return result
    }

    const getSingleChangeGraphData = (data, stock) => {
        const labels = [
            "one_day_st_change_percent",
            "one_week_st_change_percent",
            "two_week_st_change_percent",
            "one_month_st_change_percent",
            "one_day_news_change_percent",
            "one_week_news_change_percent",
            "two_week_news_change_percent",
            "one_month_news_change_percent"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st_change_percent" ||
                        durationData.fields === "one_week_st_change_percent" ||
                        durationData.fields === "two_week_st_change_percent" ||
                        durationData.fields === "one_month_st_change_percent"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news_change_percent" ||
                        durationData.fields ===
                            "one_week_news_change_percent" ||
                        durationData.fields ===
                            "two_week_news_change_percent" ||
                        durationData.fields === "one_month_news_change_percent"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    // Draw Graphs
    // 1.
    const drawBuzzGraph = (arrB, tickerList, response) => {
        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    height: 550
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },
                series: arrB
            }
        })
    }

    // 2.
    const drawSentimentGraph = (arrB, tickerList, response) => {
        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    height: 550
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        plotLines: [
                            {
                                value: graphDataCount.sentiment ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    // 3.
    const drawBuzzChangeGraph = (arrBTWO, tickerList, response) => {
        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on",
                    height: 550
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrBTWO,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        plotLines: [
                            {
                                value: graphDataCount.changeBuzz ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: arrBTWO
            }
        })
    }

    // 4.
    const drawSentimentChangeGraph = (arrB, tickerList, response) => {
        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on",
                    height: 550
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        plotLines: [
                            {
                                value: graphDataCount.changeSentiment
                                    ? null
                                    : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"],
                            className: "step-four"
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Social Sentiment : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>' //<br></br>Social Media value: {point.stAbs:,.3f} | News value: {point.newsAbs:,.3f}
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    // Get Single Buzz Graph View
    // 1.
    const getSingleBuzzGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = getSingleBuzzGraphData(stockDataByDuration)

        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function () {
                                drawBuzzGraph(arrB, tickerList, response)
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],

                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },

                series: singleGraphData
            }
        })
    }

    // 2.
    const getSingleSentimentGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    st: prevIndex,
                    news: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentGraph(arrB, tickerList, response)
                            }
                        }
                    }
                    // visible: false,
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: graphDataCount.sentiment ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: singleGraphData
            }
        })
    }

    // 3.
    const getSingleBuzzChangeGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawBuzzChangeGraph(arrB, tickerList, response)
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: graphDataCount.changeBuzz ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // 4.
    const getSingleSentimentChangeGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentChangeGraph(
                                    arrB,
                                    tickerList,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: graphDataCount.changeBuzz ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // Function to process data and draw the graph
    const processAndDrawBuzzGraph = useCallback(
        (response) => {
            const temp = findSeries(graphViews.buzz, response, 0, "buzz")

            const tickerList = sortSeries(temp["news"], temp["st"], "buzz")

            const currentTickerList = graphDataCount.buzz
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeriesTwo(
                temp["news"],
                temp["st"],
                currentTickerList
            )

            drawBuzzGraph(arr1, currentTickerList, response)
        },
        [graphViews.buzz, graphDataCount.buzz]
    )

    // Function to process data and draw the sentiment graph
    const processAndDrawSentimentGraph = useCallback(
        (response) => {
            const temp = findSeries(
                graphViews.sentiment,
                response,
                0,
                "sentiment"
            )

            const tickerList = sortSeries(temp["news"], temp["st"], "sentiment")

            const currentTickerList = graphDataCount.sentiment
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeries(currentTickerList, temp["news"], temp["st"])

            drawSentimentGraph(arr1, currentTickerList, response)
        },
        [graphViews.sentiment, graphDataCount.sentiment]
    )

    // Function to process data and draw the buzz change graph
    const processAndDrawBuzzChangeGraph = useCallback(
        (response) => {
            const data = findSeries(
                graphViews.changeBuzz,
                response,
                1,
                "buzz_change"
            )

            const tickerList = sortSeries(
                data["news"],
                data["st"],
                "buzzChange"
            )

            const currentTickerList = graphDataCount.changeBuzz
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeries(
                currentTickerList,
                data["news"],
                data["st"],
                data["newsAbs"],
                data["stAbs"]
            )

            drawBuzzChangeGraph(arr1, currentTickerList, response)
        },
        [graphViews.changeBuzz, graphDataCount.changeBuzz]
    )

    // Function to process data and draw the sentiment change graph
    const processAndDrawSentimentChangeGraph = useCallback(
        (response) => {
            const data = findSeries(graphViews.changeSentiment, response, 1)

            const tickerList = sortSeries(
                data["news"],
                data["st"],
                "sentimentChange"
            )

            const currentTickerList = graphDataCount.changeSentiment
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeries(
                currentTickerList,
                data["news"],
                data["st"],
                data["newsAbs"],
                data["stAbs"]
            )

            drawSentimentChangeGraph(arr1, currentTickerList, response)
        },
        [graphViews.changeSentiment, graphDataCount.changeSentiment]
    )

    useGraphUpdate(graphData, graphViews, "buzz", processAndDrawBuzzGraph)
    useGraphUpdate(
        graphData,
        graphViews,
        "sentiment",
        processAndDrawSentimentGraph
    )
    useGraphUpdate(
        graphData,
        graphViews,
        "changeBuzz",
        processAndDrawBuzzChangeGraph
    )
    useGraphUpdate(
        graphData,
        graphViews,
        "changeSentiment",
        processAndDrawSentimentChangeGraph
    )

    // Generic function to fetch graph data
    const fetchGraphData = async (payload, graphType, stateKey) => {
        const response = await getGraphData({
            ...payload,
            graph_type: graphType
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            [stateKey]: response
        }))

        return response
    }

    // Function to render Alerts
    const renderAlerts = async (apiResponse) => {
        setIsLoading(true)
        const date = graphDateTime
            ? dayjs(graphDateTime).format("YYYY-MM-DDTHH:mm:ssZ")
            : null

        const priceEnabled =
            alertType.value === alertTypes[0].value ? true : false

        // // If All is selected, then we don't need to pass marketStatusKey
        let response = apiResponse
        if (marketStatusKey === "all" || marketStatusKey.value === "all") {
            response = null
        }

        const alerts = await generateAlerts(date, priceEnabled, response)

        setAlerts(alerts)
        setIsLoading(false)
    }

    const getGraphs = async () => {
        if (!isLoading) {
            setIsLoading(true)

            const date = graphDateTime
                ? dayjs(graphDateTime).format("YYYY-MM-DDTHH:mm:ssZ")
                : null

            const payload = {
                date,
                marketStatusDate: date,
                marketStatusKey
            }

            let apiResponse = {}

            const buzzGraphData = await fetchGraphData(
                payload,
                "social_buzz",
                "buzz"
            )
            processAndDrawBuzzGraph(buzzGraphData)
            apiResponse = { ...apiResponse, buzz: buzzGraphData }

            const sentimentGraphData = await fetchGraphData(
                payload,
                "sentiment",
                "sentiment"
            )
            processAndDrawSentimentGraph(sentimentGraphData)
            apiResponse = { ...apiResponse, sentiment: sentimentGraphData }

            const buzzChangeGraphData = await fetchGraphData(
                payload,
                "social_buzz_change",
                "changeBuzz"
            )
            processAndDrawBuzzChangeGraph(buzzChangeGraphData)
            apiResponse = { ...apiResponse, changeBuzz: buzzChangeGraphData }

            const sentimentChangeGraphData = await fetchGraphData(
                payload,
                "sentiment_change",
                "changeSentiment"
            )
            processAndDrawSentimentChangeGraph(sentimentChangeGraphData)
            apiResponse = {
                ...apiResponse,
                changeSentiment: sentimentChangeGraphData
            }

            await renderAlerts(apiResponse)
        }

        setIsLoading(false)
    }

    useEffect(() => {
        getGraphs()
    }, [graphDateTime, marketStatusKey, graphDataCount])

    useEffect(() => {
        renderAlerts()
    }, [graphDateTime, alertType])

    const setViews = (key, date) => {
        let datesKVPair = {
            one_day: datesList[1],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        let datesKVPairChange = {
            one_day: datesList[7],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        if (key === 1) {
            setGraphViews((prevState) => {
                return { ...prevState, buzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    buzz:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 2) {
            setGraphViews((prevState) => {
                return { ...prevState, sentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    sentiment:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 3) {
            setGraphViews((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeBuzz:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 4) {
            setGraphViews((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeSentiment:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        }
    }

    const setDataCount = (key, date) => {
        if (key === 1) {
            setGraphDataCount((prevState) => {
                return { ...prevState, buzz: date }
            })
        } else if (key === 2) {
            setGraphDataCount((prevState) => {
                return { ...prevState, sentiment: date }
            })
        } else if (key === 3) {
            setGraphDataCount((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
        } else if (key === 4) {
            setGraphDataCount((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
        }
    }

    const topBar = (key) => {
        let KVPair = ["buzz", "sentiment", "changeBuzz", "changeSentiment"]
        return (
            <div className='row mb-2 ' style={{}}>
                <div className='col-md-4' style={{ marginLeft: 2 }}>
                    <Stack direction='row' spacing={0.25} alignItems='center'>
                        <Typography>Top 10</Typography>
                        <Switch
                            inputProps={{ "aria-label": "ant design" }}
                            checked={graphDataCount[KVPair[key - 1]]}
                            onChange={(event) => {
                                setDataCount(key, event.target.checked)
                            }}
                        />
                        <Typography>All</Typography>
                    </Stack>
                </div>
                <ButtonGroup
                    className='col-md-auto'
                    style={{
                        textAlign: "right",
                        marginRight: "1px !important",
                        marginLeft: "auto",
                        paddingLeft: "auto"
                    }}
                    size='small'
                    aria-label='text button group'>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 1
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 1 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_day")
                            let temp = btnGroups
                            temp[key - 1] = 1
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        1 day
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 2
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 2 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_week")
                            let temp = btnGroups
                            temp[key - 1] = 2
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        7 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 3
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 3 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_week")
                            let temp = btnGroups
                            temp[key - 1] = 3
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        14 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 4
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 4 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_month")
                            let temp = btnGroups
                            temp[key - 1] = 4
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        30 days
                    </Button>
                    {/* <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 5
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 5 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_month")
                            let temp = btnGroups
                            temp[key - 1] = 5
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        60 days
                    </Button> */}
                </ButtonGroup>
            </div>
        )
    }

    // Check Future Date
    const checkFutureData = (selectedDate) => {
        const fixedTimeValue = selectedDate.hour(8).minute(30)

        const currentDate = dayjs().tz("America/New_York")

        if (fixedTimeValue.diff(currentDate, "days", true) < 0) {
            setGraphDateTime(fixedTimeValue)
        } else {
            alert("Enter a valid date")
        }
    }

    // Handle Change Date
    const handleGetGraphWithDate = async () => {
        setDateChangeModal(false)
    }

    const getDisplayDates = () => {
        return Moment(dateJson["lastPulledDate"])
            .tz("America/New_York")
            .format("DD-MMM-YYYY ")
    }

    const selectMarketStatus = (marketStatusKey) => {
        const key = marketStatusKey ?? "all"
        setMarketStatusKey(key)

        if (marketStatusKey !== "all") setHideAlertsToggle(true)
        else setHideAlertsToggle(false)
    }

    const selectAlertType = (alertTypeKey) => {
        const alertType = alertTypes.find((item) => item.value === alertTypeKey)
        setAlertType(alertType ?? alertTypes[0])
    }

    useEffect(() => {
        let date = graphDateTime ?? dateJson["lastPulledDate"]
        date = dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ")

        let displayDate = ""

        // If Date is from JSON
        if (!graphDateTime) {
            displayDate = Moment(date)
                .hour(8)
                .minute(30)
                .format("DD MMM YYYY HH:mm A")
        } else {
            displayDate = Moment(date)
                .tz("America/New_York")
                .format("DD MMM YYYY HH:mm A")
        }

        setDisplayDate(displayDate)
    }, [graphDateTime])

    const handleJoyrideClose = (data) => {
        const { action, index, status, type } = data
        if ([EVENTS.TOUR_END].includes(type) || action === "close") {
            // setFlag((T)=>{return true;});
            setRun((t) => {
                return false
            })
            setIndex((t) => {
                return 0
            })
        } else if (
            [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
        ) {
            // Update state to advance the tour
            setIndex((prevState) => {
                return index + (action === ACTIONS.PREV ? -1 : 1)
            })
        }
        if (data.action === "close" && data.type === "step:after") {
            setRun((t) => {
                return false
            })
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={graphModalToggle}
                keepMounted
                onClose={() => {
                    setGraphToggle(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                style={{
                    width: "90%",
                    marginTop: "0",
                    maxHeight: "100%",
                    overflowY: "scroll"
                }}>
                <Slide
                    direction='up'
                    in={graphModalToggle}
                    mountOnEnter
                    unmountOnExit
                    style={{ transformOrigin: "0 0 0" }}
                    {...(graphModalToggle
                        ? { timeout: 300 }
                        : { timeout: 300 })}>
                    <div classes='container-fluid' className={classes.paper}>
                        <Joyride
                            steps={steps}
                            run={run}
                            scrollToFirstStep={true}
                            showProgress={false}
                            continuous={true}
                            callback={handleJoyrideClose}
                            stepIndex={stepIndex}
                            disableCloseOnEsc
                            disableOverlayClose
                            styles={{
                                options: {
                                    primaryColor: "#2a6ba6",
                                    padding: "5px"
                                },
                                tooltipContent: {
                                    padding: "15px 10px 2px 10px"
                                }
                            }}
                        />
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                classes={{
                                    root: classes.summaryRoot,
                                    content: classes.summaryContent
                                }}>
                                <div className='text-black text-xl font-bold'>
                                    Today's Alert
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LoadingOverlay
                                    active={isLoading}
                                    spinner={
                                        <img
                                            src={GraphLoading}
                                            style={{
                                                width: "100%",
                                                height: "auto"
                                            }}
                                        />
                                    }>
                                    <div className='flex flex-col justify-start gap-4'>
                                        {!hideAlertsToggle && (
                                            <div className='flex flex-col'>
                                                <Switcher
                                                    items={alertTypes}
                                                    onSelect={selectAlertType}
                                                    selected={alertType}
                                                />
                                            </div>
                                        )}
                                        <DashboardAlert
                                            date={displayDate}
                                            alerts={alerts}
                                        />
                                    </div>
                                </LoadingOverlay>
                            </AccordionDetails>
                        </Accordion>
                        <div className='flex items-center justify-between my-2 step-one'>
                            <div>
                                <h3
                                    className='transition-modal-description'
                                    style={{ textAlign: "left" }}>
                                    Trending Dashboards
                                </h3>
                                <div className='' style={{ color: "gray" }}>
                                    * As on Morning (Pre-Market):
                                    <Button
                                        variant='text'
                                        style={{ color: "grey", fontSize: 16 }}
                                        size='medium'
                                        onClick={() => {
                                            setDateChangeModal(() => {
                                                return true
                                            })
                                        }}>
                                        {displayDate
                                            ? dayjs(displayDate).format(
                                                  "DD MMM YYYY HH:mm A"
                                              )
                                            : getDisplayDates() + ""}
                                    </Button>
                                    <Modal
                                        aria-labelledby='transition-modal-title'
                                        aria-describedby='transition-modal-description'
                                        className={classes.modal}
                                        open={dateChangeModal}
                                        onClose={() => {
                                            setDateChangeModal(() => {
                                                return false
                                            })
                                        }}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500
                                        }}
                                        style={{
                                            left: "35%",
                                            marginTop: "3%"
                                        }}>
                                        <div
                                            classes='container-fluid '
                                            className={classes.paper}
                                            style={{ maxWidth: "50%" }}>
                                            <div className='row'>
                                                <h2
                                                    className='col-10 transition-modal-description'
                                                    style={{
                                                        textAlign: "left"
                                                    }}>
                                                    Set ET Date & Time
                                                </h2>
                                                <div className='row'>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }>
                                                        <DemoContainer
                                                            components={[
                                                                "DateTimePicker"
                                                            ]}>
                                                            <DateTimePicker
                                                                timezone='America/New_York'
                                                                value={
                                                                    graphDateTime
                                                                }
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    checkFutureData(
                                                                        newValue
                                                                    )
                                                                }}
                                                                views={[
                                                                    "year",
                                                                    "month",
                                                                    "day"
                                                                ]}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                <div className='row justify-content-center mt-3'>
                                                    <Button
                                                        className='col-md-4 confirm-btn'
                                                        style={{
                                                            backgroundColor:
                                                                "#0d6efd",
                                                            color: "white"
                                                        }}
                                                        size='small'
                                                        variant='contained'
                                                        onClick={
                                                            handleGetGraphWithDate
                                                        }>
                                                        Confirm
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            <div className='flex flex-col items-center gap-1'>
                                <p className='text-base font-medium'>
                                    Market timing Mode
                                </p>
                                <Switcher
                                    items={marketStatusItems}
                                    onSelect={selectMarketStatus}
                                    selected={marketStatusKey}
                                />
                            </div>
                            <div className='flex items-center self-end gap-6'>
                                <IconButton
                                    size='small'
                                    variant='outlined'
                                    style={{
                                        height: "50%",
                                        width: "100%",
                                        color: "#474747",
                                        borderRadius: 10,
                                        padding: 8,
                                        fontWeight: 600,
                                        fontSize: 18,
                                        backgroundColor: "#f9ce1b",
                                        boxShadow: "2px 2px lightgrey"
                                    }}
                                    className='mr-2'
                                    onClick={() => {
                                        setRun(() => {
                                            return true
                                        })
                                    }}
                                    sx={{ paddingLeft: 0, paddingRight: 2 }}>
                                    Take a Tour
                                    <InfoTwoToneIcon
                                        fontSize='medium'
                                        className='ml-2'
                                    />
                                </IconButton>
                            </div>
                            <div className='flex items-center self-end gap-6'>
                                <a
                                    href='https://finsoftai-ssi.streamlit.app/'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <Button
                                        variant='contained'
                                        style={{
                                            backgroundColor:
                                                "rgb(75, 143, 205)",
                                            color: "white",
                                            fontSize: 16,
                                            textTransform: "none" // Prevent text transformation
                                        }}
                                        size='medium'
                                        disableElevation // Disable button elevation
                                    >
                                        Ask SSi Bot
                                    </Button>
                                </a>
                                <div>
                                    <IconButton
                                        variant='outlined'
                                        onClick={() => {
                                            setGraphToggle(false)
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div
                            className='row justify-content-center'
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "1.1rem",
                                fontWeight: 400,
                                width: "100%",
                                padding: 0
                            }}>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen1}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen1.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5
                                                    }}>
                                                    <h5 className='col-md-6'>
                                                        Trending stocks by
                                                        Social Buzz
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent className='step-five'>
                                            {/* {} */}
                                            {topBar(1)}
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={buzzGraph}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen2}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen2.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5
                                                    }}>
                                                    <h5 className='col-md-6'>
                                                        Trending stocks by
                                                        Sentiment
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {topBar(2)}

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={sentiGraph}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen3}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen3.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 1
                                                    }}>
                                                    <h5 className='col-md-12'>
                                                        Trending stocks by
                                                        Change in Social Buzz
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {topBar(3)}
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={buzzGraphChange}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div className='col-md-6 mt-2 step-two'>
                                <FullScreen
                                    handle={screen4}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen4.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5
                                                    }}>
                                                    <h5 className='col-md-12'>
                                                        Trending stocks by
                                                        Change in Sentiment
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            <div className='step-three'>
                                                {topBar(4)}
                                            </div>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={sentiGraphChange}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div>
                                <Wordcloud
                                    date={
                                        graphDateTime
                                            ? dayjs(graphDateTime).format(
                                                  "YYYY-MM-DDTHH:mm:ssZ"
                                              )
                                            : null
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Slide>
            </Modal>
        </div>
    )
}

export default TrendingGraphs
