import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect, useContext } from "react"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import newsLogo from "./static/news_logo_transparent.png"
import stLogo from "./static/stocktwits_logo_transparent.png"
import IconButton from "@material-ui/core/IconButton"
import GetAppIcon from "@material-ui/icons/GetApp"
import TableFooter from "@material-ui/core/TableFooter"
import axios from "axios"
import redditLogo from "./static/reddit_logo.png"
import blueskyLogo from "./static/bluesky.jpg"
import edgarLogo from "./static/edgar.svg"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import { NavLink, Route } from "react-router-dom"
import PersonIcon from "@material-ui/icons/Person"
import FilterListIcon from "@material-ui/icons/FilterList"
import Tooltip from "@material-ui/core/Tooltip"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import Table from "./common/Table"
import { WeightContext } from "../context/WeightProvider"

const Tables = (props) => {
    const [anchorElST, setAnchorElST] = React.useState(null)
    const [anchorElNews, setAnchorElNews] = React.useState(null)
    const [anchorElReddit, setAnchorElReddit] = React.useState(null)
    const [anchorElBluesky, setAnchorElBluesky] = React.useState(null)
    const [anchorElEdgar, setAnchorElEdgar] = React.useState(null)
    const [newsList, setNewsList] = React.useState([])
    const [stList, setStList] = React.useState([])
    const [redditList, setRedditList] = React.useState([])
    const [blueskyList, setBlueskyList] = React.useState([])
    const [edgarList, setEdgarList] = React.useState([])
    // const open = Boolean(anchorElST);
    const [sortST, setSortST] = React.useState(null)
    const [sortNews, setSortNews] = React.useState(null)
    const [sortReddit, setSortReddit] = React.useState(null)
    const [sortBluesky, setSortBluesky] = React.useState(null)
    const [sortEdgar, setSortEdgar] = React.useState(null)
    const [selectedIndexST, setSelectedIndexST] = React.useState(0)
    const [selectedIndexNews, setSelectedIndexNews] = React.useState(0)
    const [selectedIndexReddit, setSelectedIndexReddit] = React.useState(0)
    const [selectedIndexBluesky, setSelectedIndexBluesky] = React.useState(0)
    const [selectedIndexEdgar, setSelectedIndexEdgar] = React.useState(0)
    const { weights } = useContext(WeightContext)

    const [newsCount, setNewsCount] = React.useState({
        positive: 0,
        negative: 0,
        neutral: 0,
        total: 0
    })

    const [stCount, setStCount] = React.useState({
        positive: 0,
        negative: 0,
        neutral: 0,
        total: 0
    })

    const [redditCount, setRedditCount] = React.useState({
        positive: 0,
        negative: 0,
        neutral: 0,
        total: 0
    })

    const [blueskyCount, setBlueskyCount] = React.useState({
        positive: 0,
        negative: 0,
        neutral: 0,
        total: 0
    })

    const [edgarCount, setEdgarCount] = React.useState({
        positive: 0,
        negative: 0,
        neutral: 0,
        total: 0
    })

    const [alertState, setAlertState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        message: "",
        contentStyle: null
    })

    const [prevAlert, setPrevAlert] = React.useState({
        Stocktwits: 0,
        News: 0
    })
    const { vertical, horizontal, alertOpen } = alertState

    const checkAlert = (src, val) => {
        if ((val > 10000) & (prevAlert[src] !== val) & !props.Run) {
            setAlertState((prevState) => {
                return {
                    ...prevState,
                    contentStyle: {
                        backgroundColor: "#008080",
                        fontSize: 18,
                        marginTop: "20%"
                    },
                    alertOpen: true,
                    message:
                        src +
                        " has surpased the limit of 10,000 documents. Please select a smaller date range."
                }
            })
            if (src === "News") {
                setPrevAlert((prevState) => {
                    return { ...prevState, News: val }
                })
            } else {
                setPrevAlert((prevState) => {
                    return { ...prevState, Stocktwits: val }
                })
            }
        }
    }

    const handleMenuST = (event) => {
        setAnchorElST(event.currentTarget)
    }

    const handleCloseST = () => {
        setAnchorElST(null)
    }
    const handleMenuNews = (event) => {
        setAnchorElNews(event.currentTarget)
    }

    const handleCloseNews = () => {
        setAnchorElNews(null)
    }

    const handleMenuReddit = (event) => {
        setAnchorElReddit(event.currentTarget)
    }

    const handleCloseReddit = () => {
        setAnchorElReddit(null)
    }

    const handleMenuBluesky = (event) => {
        setAnchorElBluesky(event.currentTarget)
    }

    const handleCloseBluesky = () => {
        setAnchorElBluesky(null)
    }

    const handleMenuEdgar = (event) => {
        setAnchorElEdgar(event.currentTarget)
    }

    const handleCloseEdgar = () => {
        setAnchorElEdgar(null)
    }

    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertOpen: false })
    }
    const quickBorder = (score) => {
        if (score > 0) {
            return "solid rgb(59, 136, 63) 6px"
        } else if (score < 0) {
            return "solid rgb(202, 59, 51) 6px"
        } else {
            return "solid rgb(248, 204, 71) 6px"
        }
    }

    const setSTValues = () => {
        if (props.stocktwitsData) {
            if (props.stocktwitsData.count) {
                checkAlert("Stocktwits", props.stocktwitsData.data.title.total)

                setStCount(() => {
                    return {
                        positive: props.stocktwitsData.count.positive,
                        negative: props.stocktwitsData.count.negative,
                        neutral: props.stocktwitsData.count.neutral,
                        total: props.stocktwitsData.data.title.total
                    }
                })
            }
        }
    }

    const setNewsValues = () => {
        if (props.newsData) {
            if (props.newsToggle === "title") {
                if (props.newsData.count && props.newsData.data.title) {
                    checkAlert("News", props.newsData.data.title.total)

                    setNewsCount(() => {
                        return {
                            positive: props.newsData.count.positive,
                            negative: props.newsData.count.negative,
                            neutral: props.newsData.count.neutral,
                            total: props.newsData.data.title.total
                        }
                    })
                }
            } else {
                if (props.newsData.count_text && props.newsData.data.text) {
                    checkAlert("News", props.newsData.data.text.total)
                    setNewsCount(() => {
                        return {
                            positive: props.newsData.count_text.positive,
                            negative: props.newsData.count_text.negative,
                            neutral: props.newsData.count_text.neutral,
                            total: props.newsData.data.text.total
                        }
                    })
                }
            }
        }
    }

    const setRedditValues = () => {
        if (props.redditData) {
            if (props.redditData.count) {
                checkAlert("reddit", props.redditData.data.title.total)

                setRedditCount(() => {
                    return {
                        positive: props.redditData.count.positive,
                        negative: props.redditData.count.negative,
                        neutral: props.redditData.count.neutral,
                        total: props.redditData.data.title.total
                    }
                })
            }
        }
    }

    const setBlueskyValues = () => {
        if (props.blueskyData) {
            if (props.blueskyData.count) {
                checkAlert("bluesky", props.blueskyData.data.title.total)

                setBlueskyCount(() => {
                    return {
                        positive: props.blueskyData.count.positive,
                        negative: props.blueskyData.count.negative,
                        neutral: props.blueskyData.count.neutral,
                        total: props.blueskyData.data.title.total
                    }
                })
            }
        }
    }

    const setEdgarValues = () => {
        if (props.edgarData) {
            if (props.edgarData.count) {
                checkAlert("reddit", props.edgarData.data.title.total)

                setEdgarCount(() => {
                    return {
                        positive: props.edgarData.count.positive,
                        negative: props.edgarData.count.negative,
                        neutral: props.edgarData.count.neutral,
                        total: props.edgarData.data.title.total
                    }
                })
            }
        }
    }

    const getSortData = (source, sortby, ascending) => {
        if (source === "Stocktwits") {
            if (sortby === "Date") {
                stList.title.data.sort((a, b) =>
                    ascending
                        ? new Date(a.Created_at2) - new Date(b.Created_at2)
                        : new Date(b.Created_at2) - new Date(a.Created_at2)
                )
                setSelectedIndexST(() => {
                    return 1
                })
            } else if (sortby === "Sentiment") {
                if (props.DQToggle === "DQ_sentiment") {
                    stList.title.data.sort((a, b) =>
                        ascending
                            ? a.DQ_sentiment - b.DQ_sentiment
                            : b.DQ_sentiment - a.DQ_sentiment
                    )
                    setSelectedIndexST(() => {
                        return 2
                    })
                } else {
                    stList.title.data.sort((a, b) =>
                        ascending
                            ? a.Sentiment_score - b.Sentiment_score
                            : b.Sentiment_score - a.Sentiment_score
                    )
                    setSelectedIndexST(() => {
                        return 2
                    })
                }
            } else {
                stList.title.data.sort((a, b) =>
                    ascending
                        ? a.rev_score - b.rev_score
                        : b.rev_score - a.rev_score
                )
                setSelectedIndexST(() => {
                    return 0
                })
            }
        } else if (source === "News") {
            if (props.newsToggle === "text") {
                if (sortby === "Date") {
                    newsList.text.data.sort((a, b) =>
                        ascending
                            ? new Date(a.Created_at2) - new Date(b.Created_at2)
                            : new Date(b.Created_at2) - new Date(a.Created_at2)
                    )
                    setSelectedIndexNews(() => {
                        return 1
                    })
                } else if (sortby === "Sentiment") {
                    newsList.text.data.sort((a, b) =>
                        ascending
                            ? a.Sentiment_score_text - b.Sentiment_score_text
                            : b.Sentiment_score_text - a.Sentiment_score_text
                    )
                    setSelectedIndexNews(() => {
                        return 2
                    })
                } else {
                    newsList.text.data.sort((a, b) =>
                        ascending
                            ? a.rev_score - b.rev_score
                            : b.rev_score - a.rev_score
                    )
                    setSelectedIndexNews(() => {
                        return 0
                    })
                }
            } else {
                if (sortby === "Date") {
                    newsList.title.data.sort((a, b) =>
                        ascending
                            ? new Date(a.Created_at2) - new Date(b.Created_at2)
                            : new Date(b.Created_at2) - new Date(a.Created_at2)
                    )
                    setSelectedIndexNews(() => {
                        return 1
                    })
                } else if (sortby === "Sentiment") {
                    newsList.title.data.sort((a, b) =>
                        ascending
                            ? a.Sentiment_score - b.Sentiment_score
                            : b.Sentiment_score - a.Sentiment_score
                    )
                    setSelectedIndexNews(() => {
                        return 2
                    })
                } else {
                    newsList.title.data.sort((a, b) => {
                        return b.rev_score - a.rev_score
                    })
                    setSelectedIndexNews(() => {
                        return 0
                    })
                }
            }
        } else if (source === "Reddit") {
            if (sortby === "Date") {
                if (props.DQToggle === "DQ_sentiment") {
                    redditList.title.data.sort((a, b) =>
                        ascending
                            ? a.DQ_sentiment - b.DQ_sentiment
                            : b.DQ_sentiment - a.DQ_sentiment
                    )
                    setSelectedIndexReddit(() => {
                        return 1
                    })
                } else {
                    redditList.title.data.sort((a, b) =>
                        ascending
                            ? a.Sentiment_score - b.Sentiment_score
                            : b.Sentiment_score - a.Sentiment_score
                    )
                    setSelectedIndexReddit(() => {
                        return 1
                    })
                }
            } else if (sortby === "Sentiment") {
                redditList.title.data.sort((a, b) =>
                    ascending
                        ? a.fsai_Sentiment_score - b.fsai_Sentiment_score
                        : b.fsai_Sentiment_score - a.fsai_Sentiment_score
                )
                setSelectedIndexReddit(() => {
                    return 2
                })
            } else {
                redditList.title.data.sort((a, b) =>
                    ascending
                        ? a.rev_score - b.rev_score
                        : b.rev_score - a.rev_score
                )
                setSelectedIndexReddit(() => {
                    return 0
                })
            }
        } else if (source === "Bluesky") {
            if (sortby === "Date") {
                if (props.DQToggle === "DQ_sentiment") {
                    blueskyList.title.data.sort((a, b) =>
                        ascending
                            ? a.DQ_sentiment - b.DQ_sentiment
                            : b.DQ_sentiment - a.DQ_sentiment
                    )
                    setSelectedIndexBluesky(() => {
                        return 1
                    })
                } else {
                    blueskyList.title.data.sort((a, b) =>
                        ascending
                            ? a.Sentiment_score - b.Sentiment_score
                            : b.Sentiment_score - a.Sentiment_score
                    )
                    setSelectedIndexBluesky(() => {
                        return 1
                    })
                }
            } else if (sortby === "Sentiment") {
                blueskyList.title.data.sort((a, b) =>
                    ascending
                        ? a.Sentiment_score - b.Sentiment_score
                        : b.Sentiment_score - a.Sentiment_score
                )
                setSelectedIndexBluesky(() => {
                    return 2
                })
            } else {
                blueskyList.title.data.sort((a, b) =>
                    ascending
                        ? a.rev_score - b.rev_score
                        : b.rev_score - a.rev_score
                )
                setSelectedIndexBluesky(() => {
                    return 0
                })
            }
        } else if (source === "Edgar") {
            if (sortby === "Date") {
                edgarList.title.data.sort((a, b) =>
                    ascending
                        ? new Date(a.filed_at) - new Date(b.filed_at)
                        : new Date(b.filed_at) - new Date(a.filed_at)
                )
                setSelectedIndexEdgar(() => {
                    return 1
                })
            } else if (sortby === "Sentiment") {
                edgarList.title.data.sort((a, b) =>
                    ascending
                        ? a.fsai_sentiment_score - b.fsai_sentiment_score
                        : b.fsai_sentiment_score - a.fsai_sentiment_score
                )
                setSelectedIndexEdgar(() => {
                    return 2
                })
            } else {
                edgarList.title.data.sort((a, b) =>
                    ascending
                        ? a.rev_score - b.rev_score
                        : b.rev_score - a.rev_score
                )
                setSelectedIndexEdgar(() => {
                    return 0
                })
            }
        }
    }

    const stTableVals = (row) => {
        return (
            <div
                className='pl-3 pb-0 mb-2'
                style={{
                    borderLeft: quickBorder(parseFloat(row[props.DQToggle])),
                    paddingLeft: 10
                }}>
                <h5
                    style={{
                        fontWeight: 700,
                        color: "rgb(0, 99, 158)",
                        marginBottom: 3
                    }}>
                    {row.Keyword
                        ? row.Keyword.map((word) => word.text).join(" ")
                        : ""}
                </h5>
                <a
                    style={{
                        marginBottom: 3,
                        fontSize: 20,
                        color: "black",
                        textDecoration: "none"
                    }}>
                    {row.Body}
                </a>
                <br></br>
                <br></br>
                <div
                    className='row mt-1'
                    style={{ fontSize: 16, color: "rgb(200, 154, 93)" }}>
                    <p className='col-4'>{row.Created_at2 + "(ET)"}</p>
                    <p className='col-4 ml-auto mr-auto'>
                        <b>Relevance: </b>
                        {row.rev_score.toFixed(3)}
                    </p>
                    <div className='col-4 ml-auto'>
                        <p>
                            <b>Sentiment score:</b>{" "}
                            {row.Sentiment_score.toFixed(3)}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const getStocktwits = () => {
        let rows = (
            <h5 style={{ color: "#adb5bd" }}>Run a Query to Load Data</h5>
        )
        try {
            if (stList && stList.title && stList.title.data.length > 0) {
                rows = stList.title.data.map((row, pos) => stTableVals(row))
            }
        } catch (err) {
            console.log(err)
        }
        return <div>{rows}</div>
    }

    const redditTableVals = (row) => {
        if (!row) return
        return (
            <div
                className='pl-3 pb-0 mb-2'
                style={{
                    borderLeft: quickBorder(parseFloat(row[props.DQToggle])),
                    paddingLeft: 10
                }}>
                {row.Keyword && row.Keyword.length > 0 && (
                    <h5
                        style={{
                            fontWeight: 700,
                            color: "rgb(0, 99, 158)",
                            marginBottom: 3
                        }}>
                        {row.Keyword.map((word) => word.text).join(" ")}
                    </h5>
                )}
                <a
                    style={{
                        marginBottom: 3,
                        fontSize: 20,
                        color: "black",
                        textDecoration: "none"
                    }}>
                    {row.Body}
                </a>
                <br></br>
                <br></br>
                <div
                    className='row mt-1'
                    style={{ fontSize: 16, color: "rgb(200, 154, 93)" }}>
                    <p className='col-4'>{row.Created_at2 + "(ET)"}</p>
                    <p className='col-4 ml-auto mr-auto'>
                        <b>Relevance: </b>
                        {row.rev_score.toFixed(3)}
                    </p>
                    {row.fsai_Sentiment_score && (
                        <div className='col-4 ml-auto'>
                            <p>
                                <b>Sentiment score:</b>{" "}
                                {row.fsai_Sentiment_score.toFixed(3)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const getReddit = () => {
        let rows = (
            <h5 style={{ color: "#adb5bd" }}>Run a Query to Load Data</h5>
        )
        try {
            if (
                redditList &&
                redditList.title &&
                redditList.title.data &&
                redditList.title.data.length > 0
            ) {
                try {
                    rows = redditList.title.data.map(redditTableVals)
                } catch (e) {
                    console.log("e", e)
                }
            }
        } catch (err) {
            console.log(err)
        }

        return <div>{rows}</div>
    }

    const getBluesky = () => {
        let rows = (
            <h5 style={{ color: "#adb5bd" }}>Run a Query to Load Data</h5>
        )
        try {
            if (
                blueskyList &&
                blueskyList.title &&
                blueskyList.title.data &&
                blueskyList.title.data.length > 0
            ) {
                try {
                    rows = blueskyList.title.data.map(blueskyTableVals)
                } catch (e) {
                    console.log("e", e)
                }
            }
        } catch (err) {
            console.log(err)
        }

        return <div>{rows}</div>
    }

    const blueskyTableVals = (row) => {
        if (!row) return
        return (
            <div
                className='pl-3 pb-0 mb-2'
                style={{
                    borderLeft: quickBorder(parseFloat(row[props.DQToggle])),
                    paddingLeft: 10
                }}>
                {row.Keyword && row.Keyword.length > 0 && (
                    <h5
                        style={{
                            fontWeight: 700,
                            color: "rgb(0, 99, 158)",
                            marginBottom: 3
                        }}>
                        {row.Keyword.map((word) => word.text).join(" ")}
                    </h5>
                )}
                <p
                    style={{
                        marginBottom: 3,
                        fontSize: 20,
                        color: "black",
                        textDecoration: "none"
                    }}>
                    {row.post_text}
                </p>
                <br></br>
                <br></br>
                <div
                    className='row mt-1'
                    style={{ fontSize: 16, color: "rgb(200, 154, 93)" }}>
                    <p className='col-4'>{row.Created_at2 + "(ET)"}</p>
                    <p className='col-4 ml-auto mr-auto'>
                        <b>Relevance: </b>
                        {row.rev_score.toFixed(3)}
                    </p>
                    {row.Sentiment_score && (
                        <div className='col-4 ml-auto'>
                            <p>
                                <b>Sentiment score:</b>{" "}
                                {row.Sentiment_score.toFixed(3)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const edgarTableVals = (row) => {
        if (!row) return

        return (
            <div
                className='pl-3 pb-0 mb-2'
                style={{
                    paddingLeft: 10
                }}>
                {row.ticker && (
                    <h5
                        style={{
                            fontWeight: 700,
                            color: "rgb(0, 99, 158)",
                            marginBottom: 3
                        }}>
                        {row.ticker} | {row.report_type} |{" "}
                        <a
                            href={row.report_link}
                            target='_blank'
                            className='text-black'>
                            Link
                        </a>
                    </h5>
                )}
                <div
                    className='row mt-1'
                    style={{ fontSize: 16, color: "rgb(200, 154, 93)" }}>
                    <b className='col-4'>{row.filed_at + "(ET)"}</b>
                    <p className='col-4 ml-auto mr-auto'>
                        <b>Relevance: </b>
                        {row.rev_score.toFixed(3)}
                    </p>
                    {row.fsai_sentiment_score && (
                        <div className='col-4 ml-auto'>
                            <p>
                                <b>Sentiment score:</b>{" "}
                                {row.fsai_sentiment_score}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const getEdgar = () => {
        let rows = (
            <h5 style={{ color: "#adb5bd" }}>Run a Query to Load Data</h5>
        )
        try {
            if (
                edgarList &&
                edgarList.title &&
                edgarList.title.data &&
                edgarList.title.data.length > 0
            ) {
                try {
                    rows = edgarList.title.data.map(edgarTableVals)
                } catch (e) {
                    console.log("e", e)
                }
            }
        } catch (err) {
            console.log(err)
        }

        return <div>{rows}</div>
    }

    const newsTableVals = (row) => {
        if (row[props.DQToggle] === null) {
            let temp = row.Keyword ? row.Keyword.slice(0, 6) : null
            return (
                <div className='pl-3 pb-0 mb-2' style={{ paddingLeft: 10 }}>
                    <a
                        href={row.url}
                        target='_blank'
                        style={{ textDecoration: "none" }}>
                        <h5
                            style={{
                                fontWeight: 700,
                                color: "rgb(0, 99, 158)",
                                marginBottom: 3
                            }}>
                            {temp
                                ? temp.map((word) => word.text).join(" ")
                                : ""}
                        </h5>
                    </a>
                    <a
                        style={{
                            marginBottom: 3,
                            fontSize: 20,
                            color: "black"
                        }}>
                        {row.Body}
                    </a>
                    <br></br>
                    <br></br>
                    <div
                        className='row mt-1'
                        style={{ fontSize: 16, color: "rgb(200, 154, 93)" }}>
                        <p className='col-4'>
                            {row.Created_at.replace("T", " ").replace("Z", "") +
                                "(ET)"}
                        </p>
                        <p className='col-4 ml-auto mr-auto'>
                            <b>Relevance: </b>
                            {row.rev_score.toFixed(3)}
                        </p>
                        <div className='col-4 ml-auto'>
                            <p>
                                <b>Sentiment score:</b> --
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            let temp = row.Keyword ? row.Keyword.slice(0, 6) : null
            return (
                <div
                    className='pl-3 pb-0 mb-2'
                    style={{
                        borderLeft: quickBorder(
                            props.newsToggle === "title"
                                ? row[props.DQToggle]
                                : row[props.DQToggle + "_text"]
                        ),
                        paddingLeft: 10
                    }}>
                    <a
                        href={row.url}
                        target='_blank'
                        style={{ textDecoration: "none" }}>
                        <h5
                            style={{
                                fontWeight: 700,
                                color: "rgb(0, 99, 158)",
                                marginBottom: 3
                            }}>
                            {temp
                                ? temp.map((word) => word.text).join(" ")
                                : ""}
                        </h5>
                    </a>
                    <a
                        style={{
                            marginBottom: 3,
                            fontSize: 20,
                            color: "black"
                        }}>
                        {row.Body}
                    </a>
                    <br></br>
                    <br></br>
                    <div
                        className='row mt-1'
                        style={{ fontSize: 16, color: "rgb(200, 154, 93)" }}>
                        <p className='col-4'>
                            {row.Created_at.replace("T", " ").replace("Z", "") +
                                "(ET)"}
                        </p>
                        <p className='col-4 ml-auto mr-auto'>
                            <b>Relevance: </b>
                            {row.rev_score.toFixed(3)}
                        </p>
                        <div className='col-4 ml-auto'>
                            <p>
                                <b>Sentiment score:</b>{" "}
                                {props.newsToggle === "title"
                                    ? row[props.DQToggle].toFixed(3)
                                    : row[props.DQToggle + "_text"].toFixed(3)}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getNews = () => {
        let rows = (
            <h5 style={{ color: "#adb5bd" }}>Run a Query to Load Data</h5>
        )
        try {
            if (newsList != []) {
                if (props.newsToggle === "text") {
                    rows = newsList.text.data.map((row, pos) =>
                        newsTableVals(row)
                    )
                } else {
                    rows = newsList.title.data.map((row, pos) =>
                        newsTableVals(row)
                    )
                }
            }
        } catch (err) {}

        return <div>{rows}</div>
    }

    const downloadNews = async () => {
        props.query["not"] = false
        let raw = { query: props.query, key: "prod" }
        axios
            .post(
                "https://utd5xk8jof.execute-api.ap-south-1.amazonaws.com/Prod/download-data",
                raw,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*/*",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((result) => {
                // result = JSON.parse(result);
                window.open(result.data["news_link"])
            })
            .catch((error) => {
                console.log("error", error.response.data)
            })
        return true
    }

    const downloadStocktwits = async () => {
        props.query["not"] = false
        let raw = { query: props.query, key: "prod" }
        axios
            .post(
                "https://utd5xk8jof.execute-api.ap-south-1.amazonaws.com/Prod/download-data",
                raw,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*/*",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((result) => {
                // result = JSON.parse(result);
                window.open(result.data["stocktwits_link"])
            })
            .catch((error) => {
                console.log("error", error.response, error)
            })
        return true
    }

    const downloadReddit = async () => {
        props.query["not"] = false
        let raw = { query: props.query, key: "prod" }
        axios
            .post(
                "https://utd5xk8jof.execute-api.ap-south-1.amazonaws.com/Prod/download-data",
                raw,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*/*",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((result) => {
                // result = JSON.parse(result);
                window.open(result.data["reddit_link"])
            })
            .catch((error) => {
                console.log("error", error.response, error)
            })
        return true
    }

    const downloadBluesky = async () => {
        props.query["not"] = false
        let raw = { query: props.query, key: "prod" }
        axios
            .post(
                "https://utd5xk8jof.execute-api.ap-south-1.amazonaws.com/Prod/download-data",
                raw,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*/*",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((result) => {
                window.open(result.data["bluesky_link"])
            })
            .catch((error) => {
                console.log("error", error.response, error)
            })
        return true
    }

    const downloadEdgar = async () => {
        props.query["not"] = false
        let raw = { query: props.query, key: "prod", edgar_flag: true }
        axios
            .post(
                "https://utd5xk8jof.execute-api.ap-south-1.amazonaws.com/Prod/download-data",
                raw,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*/*",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((result) => {
                window.open(result.data["edgar_link"])
            })
            .catch((error) => {
                console.log("error", error.response, error)
            })
        return true
    }

    useEffect(() => {
        if (props.newsData != []) {
            setNewsList((t) => {
                return props.newsData.data
            })
            setNewsValues()
        } else {
            setNewsList((t) => {
                return []
            })
        }
        if (props.stocktwitsData != []) {
            setStList((t) => {
                return props.stocktwitsData.data
            })
            setSTValues()
        } else {
            setStList((t) => {
                return []
            })
        }
        if (props.redditData != []) {
            setRedditList((t) => {
                return props.redditData.data
            })
            setRedditValues()
        } else {
            setRedditList((t) => {
                return []
            })
        }
        if (props.blueskyData != []) {
            setBlueskyList((t) => {
                return props.blueskyData.data
            })
            setBlueskyValues()
        }

        if (props.edgarData != []) {
            setEdgarList((t) => {
                return props.edgarData.data
            })
            setEdgarValues()
        } else {
            setEdgarList([])
        }
    }, [props])

    try {
        return (
            <React.Fragment>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={alertOpen}
                            onClose={handleAlertClose}
                            autoHideDuration={5000}
                            key={vertical + horizontal}
                            severity='error'
                            bodyStyle={{
                                backgroundColor: "teal",
                                color: "coral"
                            }}>
                            <SnackbarContent
                                message={alertState.message}
                                style={
                                    alertState.contentStyle
                                }></SnackbarContent>
                        </Snackbar>
                        {stList && stList.title.data.length > 0 && (
                            <Table
                                sourceName={"Stocktwits"}
                                sourceLogo={stLogo}
                                anchorElSource={anchorElST}
                                selectedSourceIndex={selectedIndexST}
                                sourceCount={stCount}
                                handleClosePopup={handleCloseST}
                                handleMenuSource={handleMenuST}
                                setSortSource={setSortST}
                                getSortData={getSortData}
                                downloadSource={downloadStocktwits}
                                getSource={getStocktwits}
                            />
                        )}
                        {newsList && newsList.text.data.length > 0 && (
                            <Table
                                sourceName={"News"}
                                sourceLogo={newsLogo}
                                anchorElSource={anchorElNews}
                                selectedSourceIndex={selectedIndexNews}
                                sourceCount={newsCount}
                                handleClosePopup={handleCloseNews}
                                handleMenuSource={handleMenuNews}
                                setSortSource={setSortNews}
                                getSortData={getSortData}
                                downloadSource={downloadNews}
                                getSource={getNews}
                            />
                        )}
                        {redditList && redditList.title.data.length > 0 && (
                            <Table
                                sourceName={"Reddit"}
                                sourceLogo={redditLogo}
                                anchorElSource={anchorElReddit}
                                selectedSourceIndex={selectedIndexReddit}
                                sourceCount={redditCount}
                                handleClosePopup={handleCloseReddit}
                                handleMenuSource={handleMenuReddit}
                                setSortSource={setSortReddit}
                                getSortData={getSortData}
                                downloadSource={downloadReddit}
                                getSource={getReddit}
                            />
                        )}
                        {/* Bluesky */}
                        {blueskyList && blueskyList.title.data.length > 0 && (
                            <Table
                                sourceName={"Bluesky"}
                                sourceLogo={blueskyLogo}
                                anchorElSource={anchorElBluesky}
                                selectedSourceIndex={selectedIndexBluesky}
                                sourceCount={blueskyCount}
                                handleClosePopup={handleCloseBluesky}
                                handleMenuSource={handleMenuBluesky}
                                setSortSource={setSortBluesky}
                                getSortData={getSortData}
                                downloadSource={downloadBluesky}
                                getSource={getBluesky}
                            />
                        )}
                        {/* Edgar */}
                        {edgarList && edgarList.title.data.length > 0 && (
                            <Table
                                sourceName={"Edgar"}
                                sourceLogo={edgarLogo}
                                anchorElSource={anchorElEdgar}
                                selectedSourceIndex={selectedIndexEdgar}
                                sourceCount={edgarCount}
                                handleClosePopup={handleCloseEdgar}
                                handleMenuSource={handleMenuEdgar}
                                setSortSource={setSortReddit}
                                getSortData={getSortData}
                                downloadSource={downloadEdgar}
                                getSource={getEdgar}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    } catch (e) {
        return (
            <React.Fragment>
                <div className='container-fluid'>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={alertOpen}
                        onClose={handleAlertClose}
                        // message={alertState.message}
                        autoHideDuration={2000}
                        key={vertical + horizontal}
                        severity='error'
                        bodyStyle={{
                            backgroundColor: "teal",
                            color: "coral",
                            fontSize: "12em"
                        }}>
                        <SnackbarContent
                            message={alertState.message}
                            style={alertState.contentStyle}></SnackbarContent>
                    </Snackbar>
                    {!weights.edgar ? (
                        <div className='row justify-content-center'>
                            <div className='col-md-6  mb-3'>
                                <Card
                                    className='ninth-step'
                                    style={{
                                        width: "100%",
                                        border: "1px solid #919191",
                                        boxShadow: "1px 1px 7px 1px lightgrey",
                                        borderRadius: 10,
                                        backgroundColor: "#f4f4f4",
                                        height: "100%",
                                        maxHeight: "90vh"
                                    }}>
                                    <CardHeader
                                        avatar={
                                            <img
                                                src={stLogo}
                                                alt=''
                                                height={60}
                                                width={33}></img>
                                        }
                                        title={
                                            <div className='d-flex'>
                                                <h3
                                                    style={{
                                                        fontWeight: 600,
                                                        marginTop: 2,
                                                        color: "rgb(68,68,68)",
                                                        fontFamily:
                                                            "Verdana, Arial"
                                                    }}>
                                                    Stocktwits
                                                </h3>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            To sort the table
                                                            below
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <div
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "0",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        <IconButton
                                                            className='sorting-step'
                                                            aria-controls='simple-menu'
                                                            aria-haspopup='true'
                                                            color='inherit'
                                                            aria-label='Person'
                                                            onClick={
                                                                handleMenuST
                                                            }>
                                                            <FilterListIcon
                                                                style={{}}
                                                            />
                                                        </IconButton>
                                                        <Menu
                                                            id='simple-menu'
                                                            keepMounted
                                                            anchorEl={
                                                                anchorElST
                                                            }
                                                            anchorOrigin={{
                                                                vertical: "top",
                                                                horizontal:
                                                                    "right"
                                                            }}
                                                            open={Boolean(
                                                                anchorElST
                                                            )}
                                                            onClose={
                                                                handleCloseST
                                                            }>
                                                            <MenuItem
                                                                selected={
                                                                    0 ===
                                                                    selectedIndexST
                                                                }
                                                                onClick={() => {
                                                                    setSortST(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Relevance"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "stocktwits",
                                                                        "Relevance"
                                                                    )
                                                                    handleCloseST()
                                                                }}>
                                                                Relevance
                                                            </MenuItem>
                                                            <MenuItem
                                                                selected={
                                                                    1 ===
                                                                    selectedIndexST
                                                                }
                                                                onClick={() => {
                                                                    setSortST(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Date"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "stocktwits",
                                                                        "Date"
                                                                    )
                                                                    handleCloseST()
                                                                }}>
                                                                Date
                                                            </MenuItem>

                                                            <MenuItem
                                                                selected={
                                                                    2 ===
                                                                    selectedIndexST
                                                                }
                                                                onClick={() => {
                                                                    setSortST(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Sentiment"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "stocktwits",
                                                                        "Sentiment"
                                                                    )
                                                                    handleCloseST()
                                                                }}>
                                                                Sentiment Score
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            Click to download
                                                            complete Stocktwits
                                                            data{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <IconButton
                                                        className='eleventh-step'
                                                        color='inherit'
                                                        onClick={
                                                            downloadStocktwits
                                                        }
                                                        style={{
                                                            marginRight: "1%",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        {" "}
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            P-Postive,
                                                            Nu-Neutral,
                                                            N-Negative and
                                                            T-Total{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <span
                                                        className='eight-step'
                                                        style={{
                                                            marginRight: "3%",
                                                            fontSize: 22,
                                                            fontWeight: 600,
                                                            marginTop: 2,
                                                            color: "rgb(68,68,68)",
                                                            fontFamily:
                                                                "Verdana, Arial"
                                                        }}>
                                                        P: 0 Nu: 0 N:0 T: 0
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        }
                                        style={{
                                            marginTop: 12,
                                            marginLeft: 25
                                        }}
                                    />
                                    <CardContent
                                        style={{
                                            overflowY: "scroll",
                                            height: "100%",
                                            overflowX: "hidden",
                                            marginRight: "20px",
                                            marginLeft: "20px",
                                            paddingBottom: "15%"
                                        }}>
                                        {getStocktwits() ? (
                                            getStocktwits()
                                        ) : (
                                            <h5
                                                style={{
                                                    color: "#f6f6f6",
                                                    fontWeight: "bold"
                                                }}>
                                                Run a Query to Load Data
                                            </h5>
                                        )}
                                    </CardContent>
                                    <TableFooter></TableFooter>
                                </Card>
                            </div>
                            <div className='col-md-6  mb-3'>
                                <Card
                                    style={{
                                        width: "100%",
                                        border: "1px solid #919191",
                                        boxShadow: "1px 1px 7px 1px lightgrey",
                                        borderRadius: 10,
                                        backgroundColor: "#f4f4f4",
                                        height: "100%",
                                        maxHeight: "90vh"
                                    }}>
                                    <CardHeader
                                        avatar={
                                            <img
                                                src={newsLogo}
                                                alt=''
                                                height={60}></img>
                                        }
                                        title={
                                            <div className='d-flex'>
                                                <h3
                                                    style={{
                                                        fontWeight: 600,
                                                        marginTop: 4,
                                                        color: "rgb(68,68,68)",
                                                        fontFamily:
                                                            "Verdana, Arial"
                                                    }}>
                                                    News
                                                </h3>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            To sort the table
                                                            below
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <div
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "0",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        <IconButton
                                                            aria-controls='simple-menu'
                                                            aria-haspopup='true'
                                                            color='inherit'
                                                            aria-label='Person'
                                                            onClick={
                                                                handleMenuNews
                                                            }>
                                                            <FilterListIcon
                                                                style={{}}
                                                            />
                                                        </IconButton>
                                                        <Menu
                                                            id='simple-menu'
                                                            keepMounted
                                                            anchorEl={
                                                                anchorElNews
                                                            }
                                                            anchorOrigin={{
                                                                vertical: "top",
                                                                horizontal:
                                                                    "right"
                                                            }}
                                                            open={Boolean(
                                                                anchorElNews
                                                            )}
                                                            onClose={
                                                                handleCloseNews
                                                            }>
                                                            <MenuItem
                                                                selected={
                                                                    0 ===
                                                                    selectedIndexNews
                                                                }
                                                                onClick={() => {
                                                                    setSortNews(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Relevance"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "news",
                                                                        "Relevance"
                                                                    )
                                                                    handleCloseNews()
                                                                }}>
                                                                Relevance
                                                            </MenuItem>
                                                            <MenuItem
                                                                selected={
                                                                    1 ===
                                                                    selectedIndexNews
                                                                }
                                                                onClick={() => {
                                                                    setSortNews(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Date"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "news",
                                                                        "Date"
                                                                    )
                                                                    handleCloseNews()
                                                                }}>
                                                                Date
                                                            </MenuItem>
                                                            <MenuItem
                                                                selected={
                                                                    2 ===
                                                                    selectedIndexNews
                                                                }
                                                                onClick={() => {
                                                                    setSortNews(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Sentiment"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "news",
                                                                        "Sentiment"
                                                                    )
                                                                    handleCloseNews()
                                                                }}>
                                                                Sentiment Score
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            Click to download
                                                            complete News data{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <IconButton
                                                        className='tenth-step'
                                                        color='inherit'
                                                        onClick={downloadNews}
                                                        style={{
                                                            marginRight: "1%",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        {" "}
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            P-Postive,
                                                            Nu-Neutral,
                                                            N-Negative and
                                                            T-Total{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <span
                                                        style={{
                                                            marginRight: "3%",
                                                            fontSize: 22,
                                                            fontWeight: 600,
                                                            marginTop: 2,
                                                            color: "rgb(68,68,68)",
                                                            fontFamily:
                                                                "Verdana, Arial"
                                                        }}>
                                                        {props.newsToggle ===
                                                        "title"
                                                            ? "P : 0" +
                                                              " Nu: 0" +
                                                              " N: 0" +
                                                              " T: 0"
                                                            : "P : 0" +
                                                              " Nu: 0" +
                                                              " N: 0" +
                                                              " T: 0"}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        }
                                        style={{
                                            marginTop: 12,
                                            marginLeft: 25
                                        }}
                                    />
                                    <CardContent
                                        style={{
                                            overflowY: "scroll",
                                            height: "100%",
                                            overflowX: "hidden",
                                            marginRight: "20px",
                                            marginLeft: "20px",
                                            paddingBottom: "15%"
                                        }}>
                                        {getNews() ? (
                                            getNews()
                                        ) : (
                                            <big
                                                style={{
                                                    color: "#f6f6f6",
                                                    fontWeight: "bold"
                                                }}>
                                                Run a Query to Load Data
                                            </big>
                                        )}
                                        <br></br>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className='col-md-6  mb-3'>
                                <Card
                                    className='reddit-step'
                                    style={{
                                        width: "100%",
                                        border: "1px solid #919191",
                                        boxShadow: "1px 1px 7px 1px lightgrey",
                                        borderRadius: 10,
                                        backgroundColor: "#f4f4f4",
                                        height: "100%",
                                        maxHeight: "90vh"
                                    }}>
                                    <CardHeader
                                        avatar={
                                            <img
                                                src={redditLogo}
                                                alt=''
                                                height={60}
                                                width={33}></img>
                                        }
                                        title={
                                            <div className='d-flex'>
                                                <h3
                                                    style={{
                                                        fontWeight: 600,
                                                        marginTop: 2,
                                                        color: "rgb(68,68,68)",
                                                        fontFamily:
                                                            "Verdana, Arial"
                                                    }}>
                                                    Reddit
                                                </h3>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            To sort the table
                                                            below
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <div
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "0",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        <IconButton
                                                            className='sorting-step'
                                                            aria-controls='simple-menu'
                                                            aria-haspopup='true'
                                                            color='inherit'
                                                            aria-label='Person'
                                                            onClick={
                                                                handleMenuReddit
                                                            }>
                                                            <FilterListIcon
                                                                style={{}}
                                                            />
                                                        </IconButton>
                                                        <Menu
                                                            id='simple-menu'
                                                            keepMounted
                                                            anchorEl={
                                                                anchorElReddit
                                                            }
                                                            anchorOrigin={{
                                                                vertical: "top",
                                                                horizontal:
                                                                    "right"
                                                            }}
                                                            open={Boolean(
                                                                anchorElReddit
                                                            )}
                                                            onClose={
                                                                handleCloseReddit
                                                            }>
                                                            <MenuItem
                                                                selected={
                                                                    0 ===
                                                                    selectedIndexReddit
                                                                }
                                                                onClick={() => {
                                                                    setSortReddit(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Relevance"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "reddit",
                                                                        "Relevance"
                                                                    )
                                                                    handleCloseReddit()
                                                                }}>
                                                                Relevance
                                                            </MenuItem>
                                                            <MenuItem
                                                                selected={
                                                                    1 ===
                                                                    selectedIndexReddit
                                                                }
                                                                onClick={() => {
                                                                    setSortReddit(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Date"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "reddit",
                                                                        "Date"
                                                                    )
                                                                    handleCloseReddit()
                                                                }}>
                                                                Date
                                                            </MenuItem>

                                                            <MenuItem
                                                                selected={
                                                                    2 ===
                                                                    selectedIndexReddit
                                                                }
                                                                onClick={() => {
                                                                    setSortReddit(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Sentiment"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "reddit",
                                                                        "Sentiment"
                                                                    )
                                                                    handleCloseReddit()
                                                                }}>
                                                                Sentiment Score
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            Click to download
                                                            complete Reddit data{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <IconButton
                                                        className='eleventh-step'
                                                        color='inherit'
                                                        onClick={downloadReddit}
                                                        style={{
                                                            marginRight: "1%",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        {" "}
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            P-Postive,
                                                            Nu-Neutral,
                                                            N-Negative and
                                                            T-Total{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <span
                                                        className='eight-step'
                                                        style={{
                                                            marginRight: "3%",
                                                            fontSize: 22,
                                                            fontWeight: 600,
                                                            marginTop: 2,
                                                            color: "rgb(68,68,68)",
                                                            fontFamily:
                                                                "Verdana, Arial"
                                                        }}>
                                                        P: 0 Nu: 0 N:0 T: 0
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        }
                                        style={{
                                            marginTop: 12,
                                            marginLeft: 25
                                        }}
                                    />
                                    <CardContent
                                        style={{
                                            overflowY: "scroll",
                                            height: "100%",
                                            overflowX: "hidden",
                                            marginRight: "20px",
                                            marginLeft: "20px",
                                            paddingBottom: "15%"
                                        }}>
                                        {getBluesky() ? (
                                            getBluesky()
                                        ) : (
                                            <h5
                                                style={{
                                                    color: "#f6f6f6",
                                                    fontWeight: "bold"
                                                }}>
                                                Run a Query to Load Data
                                            </h5>
                                        )}
                                    </CardContent>
                                    <TableFooter></TableFooter>
                                </Card>
                            </div>
                            <div className='col-md-6  mb-3'>
                                <Card
                                    className='reddit-step'
                                    style={{
                                        width: "100%",
                                        border: "1px solid #919191",
                                        boxShadow: "1px 1px 7px 1px lightgrey",
                                        borderRadius: 10,
                                        backgroundColor: "#f4f4f4",
                                        height: "100%",
                                        maxHeight: "90vh"
                                    }}>
                                    <CardHeader
                                        avatar={
                                            <img
                                                src={blueskyLogo}
                                                alt=''
                                                height={60}
                                                width={33}></img>
                                        }
                                        title={
                                            <div className='d-flex'>
                                                <h3
                                                    style={{
                                                        fontWeight: 600,
                                                        marginTop: 2,
                                                        color: "rgb(68,68,68)",
                                                        fontFamily:
                                                            "Verdana, Arial"
                                                    }}>
                                                    Bluesky
                                                </h3>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            To sort the table
                                                            below
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <div
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "0",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        <IconButton
                                                            className='sorting-step'
                                                            aria-controls='simple-menu'
                                                            aria-haspopup='true'
                                                            color='inherit'
                                                            aria-label='Person'
                                                            onClick={
                                                                handleMenuBluesky
                                                            }>
                                                            <FilterListIcon
                                                                style={{}}
                                                            />
                                                        </IconButton>
                                                        <Menu
                                                            id='simple-menu'
                                                            keepMounted
                                                            anchorEl={
                                                                anchorElBluesky
                                                            }
                                                            anchorOrigin={{
                                                                vertical: "top",
                                                                horizontal:
                                                                    "right"
                                                            }}
                                                            open={Boolean(
                                                                anchorElBluesky
                                                            )}
                                                            onClose={
                                                                handleCloseBluesky
                                                            }>
                                                            <MenuItem
                                                                selected={
                                                                    0 ===
                                                                    selectedIndexBluesky
                                                                }
                                                                onClick={() => {
                                                                    setSortBluesky(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Relevance"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "bluesky",
                                                                        "Relevance"
                                                                    )
                                                                    handleCloseBluesky()
                                                                }}>
                                                                Relevance
                                                            </MenuItem>
                                                            <MenuItem
                                                                selected={
                                                                    1 ===
                                                                    selectedIndexBluesky
                                                                }
                                                                onClick={() => {
                                                                    setSortBluesky(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Date"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "bluesky",
                                                                        "Date"
                                                                    )
                                                                    handleCloseBluesky()
                                                                }}>
                                                                Date
                                                            </MenuItem>

                                                            <MenuItem
                                                                selected={
                                                                    2 ===
                                                                    selectedIndexBluesky
                                                                }
                                                                onClick={() => {
                                                                    setSortBluesky(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            return "Sentiment"
                                                                        }
                                                                    )
                                                                    getSortData(
                                                                        "bluesky",
                                                                        "Sentiment"
                                                                    )
                                                                    handleCloseBluesky()
                                                                }}>
                                                                Sentiment Score
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            Click to download
                                                            complete Bluesky
                                                            data{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <IconButton
                                                        className='eleventh-step'
                                                        color='inherit'
                                                        onClick={
                                                            downloadBluesky
                                                        }
                                                        style={{
                                                            marginRight: "1%",
                                                            height: 50,
                                                            marginTop: -4
                                                        }}>
                                                        {" "}
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <h6>
                                                            P-Postive,
                                                            Nu-Neutral,
                                                            N-Negative and
                                                            T-Total{" "}
                                                        </h6>
                                                    }
                                                    placement='top'>
                                                    <span
                                                        className='eight-step'
                                                        style={{
                                                            marginRight: "3%",
                                                            fontSize: 22,
                                                            fontWeight: 600,
                                                            marginTop: 2,
                                                            color: "rgb(68,68,68)",
                                                            fontFamily:
                                                                "Verdana, Arial"
                                                        }}>
                                                        P: 0 Nu: 0 N:0 T: 0
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        }
                                        style={{
                                            marginTop: 12,
                                            marginLeft: 25
                                        }}
                                    />
                                    <CardContent
                                        style={{
                                            overflowY: "scroll",
                                            height: "100%",
                                            overflowX: "hidden",
                                            marginRight: "20px",
                                            marginLeft: "20px",
                                            paddingBottom: "15%"
                                        }}>
                                        {getReddit() ? (
                                            getReddit()
                                        ) : (
                                            <h5
                                                style={{
                                                    color: "#f6f6f6",
                                                    fontWeight: "bold"
                                                }}>
                                                Run a Query to Load Data
                                            </h5>
                                        )}
                                    </CardContent>
                                    <TableFooter></TableFooter>
                                </Card>
                            </div>
                        </div>
                    ) : (
                        <div className='row justify-content-center'>
                            <Table
                                sourceName={"Edgar"}
                                anchorElSource={anchorElEdgar}
                                selectedSourceIndex={selectedIndexEdgar}
                                sourceCount={edgarCount}
                                handleClosePopup={handleCloseEdgar}
                                handleMenuSource={handleMenuEdgar}
                                setSortSource={setSortReddit}
                                getSortData={getSortData}
                                downloadSource={downloadEdgar}
                                getSource={getEdgar}
                            />
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default Tables
